@font-face {
  font-family: "Sans";
  src: url(/build/3d05cc4e43b7791591faed87de19590b.ttf) format("truetype");
}
@font-face {
  font-family: "Script";
  src: url(/build/b57c74e87cc449045e7db9a646445d7a.ttf) format("truetype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background: #B3DDE3 url(/build/5105b0e091e5002d76b669f3f0652dce.svg) repeat;
  background-size: 100% auto;
  text-align: center;
}
.site-wrapper {
  position: relative;
  min-height: 100vh;
}
header {
  padding: 90px 15px;
}
header .logo {
  display: block;
  max-width: 270px;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  header {
    display: none;
  }
}
footer {
  padding-top: 25%;
}
footer .mountains {
  padding-top: 35%;
  width: 100%;
  max-height: 1220px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(/build/81fd9c087c4ab6c228374ea5c921e87c.png) center bottom repeat-x;
  background-size: auto 100%;
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (min-device-pixel-ratio: 1.5) {
  footer .mountains {
    background-image: url(/build/f21d4c8e210c7692190682fd7cde6444.png);
  }
}
footer .santa {
  position: absolute;
  bottom: 0;
  right: 2%;
  width: 17%;
}
@media only screen and (max-width: 767px) {
  footer {
    display: none;
  }
}
#game-container {
  position: relative;
  display: inline-block;
  z-index: 2;
  margin: 0 auto;
  text-align: center;
}
#game-container canvas {
  display: block;
  margin: 0 auto;
  border: 15px solid #fff;
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
}
/* Full screen overlay */
#overlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #003d49;
  /* Black background with opacity */
  z-index: 3;
  /* Specify a stack order in case you're using a different order for other elements */
  opacity: 1;
  cursor: pointer;
  /* Add a pointer on hover */
  font-family: 'Sans';
  color: #B3DDE3;
}
@media only screen and (max-width: 1024px) {
  #overlay {
    display: block;
  }
}
/* Submit score modal */
.vex-overlay {
  display: none;
}
.vex.vex-theme-plain {
  padding: 0;
  background: #145C72;
  display: flex;
}
.vex.vex-theme-plain .vex-content {
  background: #145C72;
  margin: auto;
}
.vex.vex-theme-plain .vex-close:before,
.vex.vex-theme-plain .vex-close:hover:before,
.vex.vex-theme-plain .vex-close:active:before {
  color: #ffffff;
  font-family: 'Sans';
  font-size: 35px;
  background: none;
}
#highScoreForm {
  font-family: 'Sans';
}
#highScoreForm .error {
  color: #DA3E35;
  background: #B3DDE3;
  padding: 15px;
  margin: 20px 0px;
}
#highScoreForm .error:empty {
  display: none;
}
#highScoreForm .emailForPrize {
  color: #fff;
  font-size: 0.75em;
  margin-bottom: 15px;
}
#highScoreForm .social {
  margin-top: 15px;
  margin-bottom: 30px;
  letter-spacing: 15px;
}
#highScoreForm .social .icon {
  display: inline-block;
  vertical-align: middle;
}
#highScoreForm .social .icon svg {
  display: block;
}
#highScoreForm .social .icon svg path {
  transition: all 250ms ease;
}
#highScoreForm .social .icon:hover {
  text-decoration: none;
}
#highScoreForm .social .icon:hover svg path {
  fill: #DB3B31;
}
#highScoreForm label {
  display: block;
  color: #ffffff;
  text-align: left;
  margin-bottom: 30px;
}
#highScoreForm label.required:before {
  content: '*';
  position: relative;
  color: #DA3E35;
  float: right;
  font-size: 25px;
}
#highScoreForm label input {
  display: block;
  margin-top: 5px;
  border-radius: 10px;
  padding: 15px;
  border: none;
  width: 100%;
  font-family: 'Sans';
  font-size: 16px;
}
#highScoreForm button#submitHighScore {
  display: block;
  background: url(/assets/game/submit.png) no-repeat;
  width: 229px;
  height: 66px;
  border: 0;
  float: left;
}
#highScoreForm button#submitHighScore:hover {
  cursor: pointer;
}
#highScoreForm .closeHighScore {
  color: #ffffff;
  text-decoration: none;
  line-height: 3.5em;
}
#game-credits {
  font-size: 30px;
  z-index: 2;
  position: relative;
}
#game-credits dt {
  font-family: 'Sans';
  color: #145C72;
}
#game-credits dd {
  font-family: 'Script';
  color: #003d49;
  margin-bottom: 25px;
}
h2 {
  color: #DA3E35;
  font-size: 40px;
  font-family: 'Sans';
  margin-bottom: 40px;
  margin-top: 20px;
}
a.nav {
  font-family: 'Sans';
  font-size: 25px;
  display: inline-block;
  line-height: 40px;
  text-decoration: none;
  color: #145C72;
  /* TODO: Positioning with submit score modal */
  position: relative;
  z-index: 1;
}
a.nav:before {
  content: '';
  width: 40px;
  height: 40px;
  margin-right: 10px;
  float: left;
}
a.nav.credits {
  margin-top: 30px;
}
a.nav.credits:before {
  background: url(/assets/game/mainmenu.png) no-repeat;
  background-size: 80px 40px;
}
a.nav.play-again {
  margin-bottom: 40px;
}
a.nav.play-again:before {
  background: url(/assets/game/restart.png) no-repeat;
  background-size: 80px 40px;
}
